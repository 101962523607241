import React from "react"
import Layout from "../components/layout"
import Group from "../components/Group"
import QAItem from "../components/qaItem"
import SEO from "../components/seo"
import { Routing, getId, getTitle } from "../utils/routing"
import ProjectName from "../components/ProjectName"

const RC = Routing.TERMS_OF_USE.sub
const title = Routing.TERMS_OF_USE.title

const TermsOfUse = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title={title} />
      <h1>{title}</h1>
      Terms of Use These “Terms of Use” have been updated as of August 8, 2019.
      <Group id={getId(RC.ACCEPTANCE_OF_TERMS)}>
        <h2>1. {getTitle(RC.ACCEPTANCE_OF_TERMS)}</h2>
        <p>
          <ProjectName company /> (“We”, “Us”, or “<ProjectName />
          ”) provide a sports and gaming website located online at (the "Site")
          and a related mobile app which include all of the text, images, audio,
          code and other material they contain or provide (collectively, the
          “Content”) and all of the features, contests and other services they
          provide. Residents of the United States will be contracting with{" "}
          <ProjectName />
          Exchange, LLC. The Site, any related mobile app, and any other
          features, tools, materials, or other services (including co-branded or
          affiliated services) offered from time to time by <ProjectName /> are
          referred to here as the “Service.” Please read these Terms of Use (the
          “Terms” or “Terms of Use”) carefully before using the Service. By
          using or otherwise accessing the Service, or clicking to accept or
          agree to these Terms where that option is made available, you (1)
          accept and agree to these Terms and our additional rules and scoring
          system and (2) consent to the collection, use, disclosure and other
          handling of information as described in our Privacy Policy and (3) any
          additional terms, rules and conditions of participation in particular
          contests issued by <ProjectName /> from time to time. If you do not
          agree to the Terms, then you may not access or use the Content or
          Services.
        </p>
        <p>
          <ProjectName /> may issue additional terms, rules and conditions of
          participation in particular contests. For example, <ProjectName /> may
          issue conditions as required by various state rules and regulations,
          which may impact your experience or participation on the Service. You
          agree to be subject to those additional rules if you participate in
          such contests.
        </p>
      </Group>
      <Group id={getId(RC.MODIFICATION_OF_TERMS_OF_USE)}>
        <h2>2. {getTitle(RC.MODIFICATION_OF_TERMS_OF_USE)}</h2>
        <p>
          Except for Section 15, providing for binding arbitration and waiver of
          class action rights, <ProjectName /> reserves the right, at its sole
          discretion, to modify or replace the Terms of Use at any time. The
          most current version of these Terms will be posted on our Site. You
          shall be responsible for reviewing and becoming familiar with any such
          modifications. If a revision to the Terms, in our sole discretion, is
          material, we will notify you by contacting you through the email
          address associated with your account. Use of the Services by you after
          any modification to the Terms constitutes your acceptance of the Terms
          of Use as modified.
        </p>
      </Group>
      <Group id={getId(RC.ELIGIBILITY)}>
        <h2>3. {getTitle(RC.ELIGIBILITY)}</h2>
        <p>
          You hereby represent and warrant that you are fully able and competent
          to enter into the terms, conditions, obligations, affirmations,
          representations and warranties set forth in these terms and to abide
          by and comply with these terms.
        </p>
        <p>
          By depositing money or entering a contest, you are representing and
          warranting that:
        </p>
        <ul>
          <li>
            you are of 18 years of age or older (21 years of age or older in
            Massachusetts)
          </li>
          <li>
            you are a citizen or resident of the United States of America or
            Canada and that you have an address in the United States of America
            or Canada;
          </li>
          <li>
            at the time of deposit or game entry you are physically located in
            the United States of America or Canada in a jurisdiction in which
            participation in the contest is not prohibited by applicable law;
          </li>
          <li>
            you are not listed on any U.S. Government list of prohibited or
            restricted parties;
          </li>
          <li>
            you will abide at all times by these Terms of Use and any other
            agreements between you and <ProjectName /> regarding your use of the
            Service or participation in games;
          </li>
          <li>
            when depositing funds or entering a paid event, you are not
            physically located in of any of the following states: Alabama,
            Arizona, Hawaii, Idaho, Iowa, Louisiana, Montana, Nevada, Texas or
            Washington;
          </li>
          <li>
            you are not subject to backup withholding tax because: (a) you are
            exempt from backup withholding, or (b) you have not been notified by
            the Internal Revenue Service (IRS) that you are subject to backup
            withholding as a result of a failure to report all interest or
            dividends, or (c) the IRS has notified you that you are no longer
            subject to backup withholding.
          </li>
        </ul>

        <p>
          If <ProjectName /> determines that you do not meet the eligibility
          requirements of this section, then you are not authorized to use the
          Service. <ProjectName /> may require you to provide proof that you are
          eligible to participate according to this section prior to receiving a
          prize. This includes by requesting that you fill out an affidavit of
          eligibility or other verification information (as discussed in Section
          4 below). If <ProjectName /> otherwise determines that you do not meet
          the eligibility requirements of this section, in addition to any
          rights that <ProjectName /> may have in law or equity, <ProjectName />{" "}
          reserves the right to terminate your account, withhold or revoke the
          awarding of any prizes associated with your account or limit your
          ability to withdraw. In such a situation, <ProjectName /> may pay out
          any withheld or revoked prizes to the other entrants in the relevant
          event in a manner consistent with the prize structure of the contest,
          to be precisely determined by <ProjectName /> in its sole discretion.{" "}
          <ProjectName /> also reserves the right to withhold revoked prizes to
          use in furtherance of its fraud prevention or anti-money laundering
          efforts. <ProjectName />
          employees may use the Service for the purpose of testing the user
          experience but may not withdraw money or prizes except when playing in
          a private league. Relatives of <ProjectName /> employees with whom
          they share a household are not eligible to participate in paid
          contests unless they are private contests with other <ProjectName />{" "}
          employees or household members. <ProjectName /> consultants or
          promoters of the Service may play in contests without such limitation,
          but only if (i) their arrangement with <ProjectName /> does not permit
          them to have any access to non-public Service data or any other data
          not made available to all players on the Service and (ii) they do not
          receive any other advantages in their play on the Service.
        </p>
        <p>
          <ProjectName /> may maintain information about any ineligible player
          sufficient to assist <ProjectName /> in blocking the user from
          entering future <ProjectName /> contests, unless and until{" "}
          <ProjectName /> determines, in its sole discretion, that the entrant
          is no longer ineligible.
        </p>
        <p>
          Athletes, coaches and other team management, team support personnel
          (e.g. without limitation, team physicians) and team owners may not
          participate in any <ProjectName /> contests in the sport or sports
          with which they’re associated. Team owners, referees, league
          employees, sports commissioners and other individuals who through an
          ownership interest or game-related employment can influence the
          gameplay are likewise ineligible.
        </p>
        <p>
          <ProjectName /> is not affiliated with or sponsored by the National
          Football League, the National Hockey League or Major League Baseball.
        </p>
      </Group>
      <Group id={getId(RC.CONDITIONS_OF_PARTICIPATION)}>
        <h2>4. {getTitle(RC.CONDITIONS_OF_PARTICIPATION)}</h2>
        <QAItem>
          <p>4.1 Registration</p>
          <p>
            In order to participate in a contest on the Service, you must
            register for an account. By registering as a user of the Service,
            you agree to provide accurate, current and complete information
            about yourself as prompted (such information being the “Registration
            Data”) and maintain and promptly update the Registration Data to
            keep it accurate, current and complete. If you provide any
            information that is inaccurate, not current or incomplete, or
            <ProjectName /> has reasonable grounds to suspect that such
            information is inaccurate, not current or incomplete,{" "}
            <ProjectName /> may deny you access to areas requiring registration,
            or terminate your account, at its sole discretion.
          </p>
          <p>
            You may establish, maintain, use and control only one account on the
            Service. Each account on the Service may only be owned, maintained,
            used and controlled by one individual. For avoidance of doubt, users
            may not “co-own” accounts on the Service. In the event{" "}
            <ProjectName />
            determines that you have opened, maintained, used or controlled more
            than one account, in addition to any other rights that{" "}
            <ProjectName />
            may have, <ProjectName /> reserves the right to suspend or terminate
            any or all of your accounts and terminate, withhold or revoke the
            awarding of any prizes.
          </p>
          <p>
            You agree that the sole and specific purpose of creating an account
            on <ProjectName /> is to participate in sports and gaming event on
            the Service. <ProjectName /> shall be entitled to suspend, limit or
            terminate your account if we determine, in our sole discretion, that
            you are depositing funds without the intention of using them in
            contests on the Service. In such circumstances, we may also report
            such activity to relevant authorities.
          </p>
        </QAItem>
        <QAItem>
          <p>4.2 Account Password and Security</p>
          <p>
            At the time of registration for online account access, you must
            provide a valid email address and supply a Username and Password to
            be used in conjunction with your account. You may not use a Username
            that promotes a commercial venture or a Username that{" "}
            <ProjectName /> in its sole discretion deems offensive. Many
            portions of the Service require additional registration or
            credentials for access (the "Restricted Areas"). You are responsible
            for maintaining the confidentiality of your Password, and are fully
            responsible for all uses of your Username and Password, whether by
            you or others. You agree to (a) never to use the same Password for
            the Service that you use or have ever used outside of the Service;
            (b) keep your Username and Password confidential and not share them
            with anyone else; (c) immediately notify <ProjectName /> of any
            unauthorized use of your Username and Password or account or any
            other breach of security; and (d) use only your own Username and
            Password to access the Service’s Restricted Areas. <ProjectName />{" "}
            cannot and will not be liable for any loss or damage arising from
            your failure to comply with these Terms.
          </p>
          <p>
            You acknowledge and agree that <ProjectName /> is authorized to act
            on instructions received through the account utilizing your Username
            and Password, and that <ProjectName /> may, but is not obligated to,
            deny access or block any transaction made through use of your
            Username and Password without prior notice if we believe your
            Username and Password are being used by someone other than you, or
            for any other reason.
          </p>
          <p>
            Under certain circumstances, in their sole discretion,{" "}
            <ProjectName />
            may require you to change your Username or may unilaterally change
            your Username.
          </p>
        </QAItem>
        <QAItem>
          <p>4.3 Communications and Information Practices</p>
          <p>
            As a result of your registration for the Service, you may receive
            certain commercial communications from <ProjectName />. You
            understand and agree that these communications are part of your
            registration, and that, to the extent required by law, you may opt
            out of receiving these communications at any time by either using
            the unsubscribe functionality or sending an email to info at info@
            <ProjectName />
            .com Following such an opt-out, we may still communicate with you
            via email to the extent permitted by applicable law.
          </p>
        </QAItem>
        <QAItem>
          <p>4.4 Disqualification and Cancellation</p>
          <p>
            <ProjectName /> also reserves the right to cancel events, in our
            sole discretion, without any restrictions.
          </p>
          <p>
            <ProjectName />, in its sole discretion, may disqualify you from an
            event or the entire Service, refuse to award points or prizes and
            require the return of any prizes, or suspend, limit, or terminate
            your account if you engage in conduct <ProjectName /> deems, in its
            sole discretion, to be improper, unfair, fraudulent or otherwise
            adverse to the operation of the Service or in any way detrimental to
            other users. Improper conduct includes, but is not limited to:
            falsifying personal information, including payment information,
            required to use the Service or claim a prize; violating eligible
            payment method terms, including the terms of any cash rewards
            payment card, violating any of these rules, accumulating points or
            prizes through unauthorized methods such as unauthorized scripts or
            other automated means; tampering with the administration of the
            Service or trying to in any way tamper with the computer programs
            associated with the Service; obtaining other entrants’ information
            and spamming other entrants; and abusing the Service in any way; or
            otherwise violating these Terms of Use. You acknowledge that the
            forfeiture and/or return of any prize shall in no way prevent{" "}
            <ProjectName /> from informing the relevant authorities, and/or
            pursuing criminal or civil proceedings in connection with such
            conduct.
          </p>
          <p>
            If for any reason the Service is not running as originally planned
            (e.g., if the Site becomes corrupted or does not allow the proper
            usage and processing of entries in accordance with the rules, or if
            infection by a computer virus, bugs, tampering, unauthorized
            intervention, actions by entrants, fraud, technical failures, or any
            other causes of any kind, in the sole opinion of <ProjectName />{" "}
            corrupts or affects the administration, security, fair ness,
            integrity or proper conduct of the Service), <ProjectName />{" "}
            reserves the right, in its sole discretion, to disqualify any
            individual implicated in or relating to the cause and/or to cancel,
            terminate, extend, modify or suspend the Service, and select the
            winner(s) from all eligible entries received. If such cancellation,
            termination, modification or suspension occurs, notification may be
            posted on the Site.
          </p>
          <p>
            The failure of <ProjectName /> to comply with any provision of these
            Terms due to an act of God, hurricane, war, fire, riot, earthquake,
            terrorism, act of public enemies, actions of governmental
            authorities outside of the control of <ProjectName /> (excepting
            compliance with applicable codes and regulations) or other force
            majeure event will not be considered a breach of these Terms.
          </p>
        </QAItem>
        <QAItem>
        <p>4.5 Deposits and Withdrawals Generally</p>
        <p>
          By depositing funds for an event, you agree to provide us with a valid
          mailing address, date of birth and any other information we may
          require in order to run appropriate identity checks and comply with
          applicable rules and regulations. If necessary, you may be required to
          provide additional documentation that allows us to verify you. While
          your account is pending verification, you may be able to deposit funds
          into your account.
        </p>
        <p>
          We also may conduct checks for Terms compliance, including anti-fraud
          checks on playing patterns and deposits prior to processing a
          withdrawal, and we may request additional information before
          permitting a withdrawal. Subject to such checks, you may close your
          account and withdraw your deposits and/or winnings at any time and for
          any reason.
        </p>
        <p>
          Deposits, and player winnings after contests are finished and if
          player elects to cash out, are held in a separate, segregated bank
          account by a subsidiary of <ProjectName />. These funds belong to you,
          subject to review for evidence of fraud, verification or other
          prohibited conduct as described above, and <ProjectName /> may not use
          them to cover its operating expenses or for other purposes.
        </p>
        <p>
          <ProjectName /> may limit the amount a user can deposit into his or
          her user account in accordance with state-imposed deposit limits.
        </p>
        </QAItem>

        <QAItem>
          <p>4.6 Taxation</p>
          <p>
            Each year all winners who have won $600 or more must provide updated
            details to <ProjectName /> which will be used to allow{" "}
            <ProjectName /> to comply with tax regulations and may be shared
            with appropriate tax authorities. You, not <ProjectName />, are
            responsible for filing and paying applicable state and federal taxes
            on any winnings.
            <ProjectName /> does not provide tax advice, nor should any
            statements in this agreement or on the Service be construed as tax
            advice.
          </p>
        </QAItem>

        <QAItem>
          <p>4.7 Publicity</p>
          <p>
            By entering an event, you consent to <ProjectName />
            ’s and its service providers’ and business partners’ use of your
            name, voice, likeness, location and photograph in connection with
            the development, production, distribution and/or exploitation
            (including marketing and promotion) of the selected event and/or
            other <ProjectName /> events and <ProjectName /> generally, unless
            otherwise prohibited by law.
            <ProjectName /> and its business partners reserve the right to make
            public statements about the entrants and winner(s), on-air, on the
            Internet, or otherwise, prior to, during, or following the event.
            Entrants agree that <ProjectName /> may announce any winner's name
            on-air or on any of its websites or any other location at any time
            in connection with the marketing and promotion of <ProjectName /> or
            other events or games operated by <ProjectName />. You agree that
            participation in and (where applicable) the winning of a monies in
            connection with an event constitute complete compensation for your
            obligations under this paragraph, and you agree not to seek to
            charge a fee or impose other conditions on the fulfillment of these
            obligations. The rules specific to certain contests may contain
            additional publicity obligations or may require a written signature
            on a separate publicity waiver.
          </p>
        </QAItem>
      </Group>
      <Group id={getId(RC.GAME_RULES)}>
        <h2>5. {getTitle(RC.GAME_RULES)}</h2>

        <QAItem>
          <p>5.1 Game of Skill</p>
          <p>
            <ProjectName /> is a game of skill. Winners are determined by the
            criteria stated in each event rules. For each event, winners are
            determined by the individuals who use their skill and knowledge of
            relevant professional sports information. Fantasy points are
            accumulated through the performance of individual athletes in sports
            events. Participants may trade with others, in and out of their
            gaming positions prior to the end of game.
          </p>
          <p>GAME PLAY RULES</p>
          <p>
            There will be multiple contest to choose from on <ProjectName />{" "}
            site
          </p>
          <p>
            Fantasy Scoring will be the first contest offered. Point spreads in
            Fantasy Scoring will be based on historical data which will
            determine an expected score for each fantasy player. The point
            spread will be baked into the contest. The total expected Fantasy
            Scores of Team A will be matched up against the expected fantasy
            scores of team B and the point spread applied to the final scoring.
          </p>
          <p>
            The total fantasy points accumulated during the actual game (subject
            to the point spread) determines the final outcome.
          </p>
          <p>
            EXAMPLE: Team A expected fantasy points =100. Team B expected
            fantasy point=95.
          </p>
          <p>
            Point spread in this above game is Team A -5points which means Team
            A total actual fantasy point must be 5 points greater than Team B to
            win. If the final fantasy scores are Team A: 98 points and Team B:
            95 points, , participants who purchased Team B either at the
            beginning of the game, or traded into a Team B position during the
            game, and held the position until the end were winners and could
            multiply the number of units they own by One Dollar $1. If they
            purchased units at 50 cents their profit is 50 cents times the
            number of units purchased and held.
          </p>
          <p>
            Participants who owned the losing team at the end of the Game , own
            units worth ZERO $0 at the close of the contest.
          </p>
          <p>
            A complete list of scoring categories and values may be found in the
            <ProjectName /> website below.
          </p>
          <p>
            Key to the contest and what make <ProjectName /> unique, is that
            participants may trade in and out of positions with other
            participants as the contests progress. This is unique as no party is
            locked into a particular scoring scenario as the participants are
            encouraged to use their skill to trade in and out of positions.
          </p>
          <p>
            Participants will dictate the opening price of the game being
            played. Because of a point spread (Handicap) we can suggest that a
            game starts at 50 cents, but just like any auction market the price
            will be set by the individuals that are participating
          </p>
          <p>
            Participants will choose either to BUY (team must cover handicap) or
            SELL (team does not cover handicap) which is based on the final
            score. The winning side after the final score is determined will be
            worth 100 cents or $1, and the losing team will be worth ZERO
          </p>
          <p>
            Once the game begins participants can choose to hold onto those
            units until the final score is determined, or trade them back to a
            willing participant who has agreed on a price and thus accepts the
            first participants position. Example: Player A buys 100 units of NY
            Giants at 50 cents. After 5 minutes of real time game action Player
            A decodes he wants to sell the 100 Units at 60 cents. Player B feels
            this is still a good purchase and decides to Buy the 100 from Player
            A.
          </p>
          <p>
            Player A just secured a 10-cent profit on his purchase. The 10 cents
            times the number of units that were purchased determines the amount
            of money Player A won. Player A can now establish a new position
            depending on his new opinion of the game.
          </p>
          <p>
            Player B if he’s correct and holds to the end of the game can make
            40 cents on his purchase. Player B can also sell his position at any
            time during the game,(as long as theirs a buyer will to buy the
            position from them).
          </p>
          <p>
            All monies from purchases will be deducted from participants
            accounts.
          </p>
          <p>
            All profitable trades when closed will be added back into
            participants accounts
          </p>
          <p>
            You will not be allowed to purchase more units that your account can
            cover
          </p>
          <p>
            Participants can reuse their monies immediately after their position
            are sold
          </p>
        </QAItem>
        <QAItem>
          <p>5.2 Entry fees</p>
          <p>
            There are no entry fees associated with <ProjectName />. Instead
            each individual will purchase or sell units of a set team. The
            number of units multiplied by the price of the purchase or sale
            determines how much money is deducted from a User’s account. When a
            user opts to participate in an event, the applicable amount will be
            debited from your <ProjectName /> account. In the event of a dispute
            regarding the identity of the person submitting an entry, the entry
            will be deemed submitted by the person in whose Username the entry
            was submitted, or if possession of the Username itself is contested
            and in
            <ProjectName />
            ’s opinion sufficiently uncertain, the name in which the email
            address on file was registered with the email service provider.{" "}
            <ProjectName /> reserves the right not to award a prize to an
            individual it believes in its sole discretion did not submit the
            winning entry.
          </p>
          <p>
            Users may cancel orders up to thirty (30) minutes prior to game
            start. No executed orders can be canceled. Limit orders can be
            canceled at any time. If you timely cancel an order, your order will
            be refunded back to your <ProjectName /> account.
          </p>
        </QAItem>
        <QAItem>
          <p>5.3 Contest Term</p>
          <p>
            <ProjectName /> offers contests for professional sports events
            generally taking place over a variety of time periods.
          </p>
        </QAItem>
        <QAItem>
          <p>5.4 Awards</p>
          <p>
            After each event ends, positions on the correct side will be
            credited back to the persons account but remain subject to final
            verification. In the event of a tie, the prize may be divided evenly
            between the tied players, unless otherwise specified.{" "}
            <ProjectName />
            also has an exchange-like function, a trading platform that tracks
            the trading of units multiplied by their price.
          </p>
          <p>
            Winning and losing calculations are based on price and unit amount
            at the time of closing of a position or the results as of the time
            when final scoring is tabulated by <ProjectName />. The scoring
            results will not be changed in light of official adjustments made by
            the professional leagues, though we reserve the right to make
            adjustments based on errors or irregularities in the transmission of
            information to us from our stats provider or in our calculation of
            results. We also may make adjustments in the event of noncompliance
            with the Terms. <ProjectName /> has no obligation to delay the
            awarding of a prize in anticipation of any adjustment, and we
            reserve the right to reverse payments in the event of any
            adjustment. You agree to cooperate with our efforts to reverse
            payments.
          </p>
          <p>
            No substitution or transfer of a prize is permitted. All taxes
            associated with the receipt or use of any prize are the sole
            responsibility of the winner. In the event that the awarding of any
            prizes to winners of the Contest is challenged by any legal
            authority, <ProjectName /> reserves the right in its sole discretion
            to determine whether or not to award or adjust such prizes.
          </p>
        </QAItem>
        <QAItem>
          <p>5.5 Notification</p>
          <p>
            Winners may be requested to return via email or regular mail an
            affidavit of eligibility, a publicity agreement and appropriate tax
            forms by a specified deadline. Failure to comply with this
            requirement can result in disqualification. Any prize notification
            returned as undeliverable may result in disqualification and
            selection of an alternate winner. In addition, a list of winners and
            winner's names for each competition period may be obtained by
            writing to: <ProjectName company />, <ProjectName address />.
          </p>
        </QAItem>
        <QAItem>
          <p>5.6 Bonuses and Promotions</p>
          <p>
            <ProjectName /> may offer bonuses from time to time to users for
            marketing purposes. A user’s bonus may be converted into a monetary
            credit that can be used to play on <ProjectName /> as the user
            enters real money contests in accordance with the terms of the offer
            or promotion. Unless otherwise stated, any unconverted pending bonus
            remaining in a player’s account 60 days after it has been initially
            credited can be removed by <ProjectName />. Additionally, if a user
            immediately withdraws money after a deposit, a deposit bonus will be
            retracted.
          </p>

          <p>
            In the event of abuse of the bonus system by any user,{" "}
            <ProjectName />
            reserves the right to retract your user bonuses.
          </p>
        </QAItem>
      </Group>
      <Group>
        <h2 id={getId(RC.CONDUCT)}>6. {getTitle(RC.CONDUCT)}</h2>
        <p>
          As a condition of use, you promise not to use the Services for any
          purpose that is unlawful or prohibited by these Terms, or any other
          purpose not reasonably intended by <ProjectName />. By way of example,
          and not as a limitation, you agree not to:
        </p>
        <ul>
          <li>
            abuse, harass, impersonate, intimidate or threaten other{" "}
            <ProjectName />
            users;
          </li>
          <li>
            post or transmit, or cause to be posted or transmitted, any Content
            that are infringing, libelous, defamatory, abusive, offensive,
            obscene, pornographic or otherwise violates any law or right of any
            third party;
          </li>
          <li>
            use the Service for any unauthorized purpose, or in violation of any
            applicable law, including intellectual property laws;
          </li>
          <li>
            post or transmit, or cause to be posted or transmitted, any
            communication or solicitation designed or intended to obtain
            password, account, or private information from any <ProjectName />{" "}
            user;
          </li>
          <li>
            create or submit unwanted email ("Spam") to any other{" "}
            <ProjectName />
            users;
          </li>
          <li>
            infringe upon the intellectual property rights of <ProjectName />,
            its users, or any third party;
          </li>
          <li>
            submit comments linking to affiliate programs, multi-level marketing
            schemes, sites repurposing existing stories or off-topic content;
          </li>
          <li>
            post, email, transmit, upload, or otherwise make available any
            material that contains software viruses or any other computer code,
            files or programs designed or functioning to interrupt, destroy, or
            limit the functionality of any computer software or hardware or
            telecommunications equipment;
          </li>
          <li>
            use any robot, spider, scraper, sniping software or other automated
            means to access the Service for any purpose (except for RSS feed
            access) without our express written permission. Additionally, you
            agree that you will not: (1) take any action that imposes, or may
            impose in our sole discretion an unreasonable or disproportionately
            large load on our infrastructure; (2) interfere or attempt to
            interfere with the proper working of the Service or any activities
            conducted on the Service; or (3) bypass any measures we may use to
            prevent or restrict access to the Service;
          </li>
          <li>
            use artificial means, including creating multiple user accounts, to
            inflate your position and standing with the <ProjectName /> leader
            boards and community;
          </li>
          <li>
            use unauthorized scripts; all authorized scripts will be made
            available through the Service prior to game entry;
          </li>
          <li>
            advertise to, or solicit, any user to buy or sell any products or
            Service, or use any information obtained from the Service in order
            to contact, advertise to, solicit, or sell to users without their
            prior explicit consent;
          </li>
          <li>sell or otherwise transfer your profile;</li>
          <li>
            attempt to influence the play in any sporting event from which
            athletes are available for selection in <ProjectName /> contests on
            <ProjectName /> in which you are involved or in which you have a
            direct or indirect interest.
          </li>
          <li>
            enter into contests, by any means including multi-accounting, for
            which you are ineligible (e.g., beginner contests when you are not a
            beginner).
          </li>
        </ul>

        <p>
          Violation of our rules may result in the removal of your Content from
          the Service, forfeiture of winnings, and/or the canceling of your
          account. You acknowledge and agree that <ProjectName /> may remove any
          User Content (as defined below) and terminate any <ProjectName />{" "}
          account at any time for any reason (including, but not limited to,
          upon receipt of claims or allegations from third parties or
          authorities relating to such User Content). To report Terms of Use
          violations, please contact us.
        </p>

        <QAItem>
          <p>6.1 User Content</p>
          <p>
            You understand that all content made available on the Service by a
            user (“User Content”), including but not limited to profile
            information and communications with other users, whether privately
            transmitted or made publicly available, is the sole responsibility
            of the person from which such User Content originated. This means
            that you, not <ProjectName />, are entirely responsible for all User
            Content that you upload, post, share, email, transmit, or otherwise
            make available via the Service. Under no circumstances will
            <ProjectName /> be liable in any way for any User Content.
          </p>
          <p>
            You acknowledge that <ProjectName /> may or may not pre-screen User
            Content, but that <ProjectName /> and its designees have the right
            (but not the obligation) in their sole discretion to pre-screen,
            refuse, permanently delete, undelete, modify and/or move any User
            Content available via the Service. Without limiting the foregoing,
            <ProjectName /> and its designees shall have the right to remove any
            User Content that violates these Terms or is otherwise objectionable
            in
            <ProjectName />
            's sole discretion. You understand that by using the Service, you
            may be exposed to User Content that you may consider to be offensive
            or objectionable. You agree that you must evaluate, and bear all
            risks associated with, the use or disclosure of any User Content.
            You further acknowledge and agree that you bear the sole risk of
            reliance on any Content available on or through the Service.
          </p>
          <p>
            With respect to User Content you submit or otherwise make available
            on or to the Service, you grant <ProjectName /> an irrevocable,
            fully sub-licensable, perpetual, world-wide, royalty-free,
            non-exclusive license to use, distribute, reproduce, modify, adapt,
            publish, translate, publicly perform and publicly display such User
            Content (in whole or in part), and to incorporate such User Content
            into other works, in any format or medium now known or later
            developed.
          </p>
          <p>
            You are solely responsible for your interactions with other users of
            the Service. <ProjectName /> reserves the right, but has no
            obligation, to monitor disputes between you and other users.
          </p>
        </QAItem>
      </Group>
      <Group id={getId(RC.INDEMNITY)}>
        <h2>7. {getTitle(RC.INDEMNITY)}</h2>
        <p>
          You agree to release and to indemnify, defend and hold harmless
          <ProjectName /> and its parents, subsidiaries, affiliates and
          agencies, as well as the officers, directors, employees, shareholders
          and representatives of any of the foregoing entities, from and against
          any and all losses, liabilities, expenses, damages, costs (including
          attorneys’ fees and court costs) claims or actions of any kind
          whatsoever arising or resulting from your use of the Service, your
          violation of these Terms of Use, your receipt, ownership, use or
          misuse of any prize, and any of your acts or omissions that implicate
          publicity rights, defamation or invasion of privacy. <ProjectName />
          reserves the right, at its own expense, to assume exclusive defense
          and control of any matter otherwise subject to indemnification by you
          and, in such case, you agree to cooperate with <ProjectName /> in the
          defense of such matter.
        </p>
      </Group>
      <Group id={getId(RC.WARRANTY_DISCLAIMERS)}>
        <h2>8. {getTitle(RC.WARRANTY_DISCLAIMERS)}</h2>
        <p>
          You expressly understand and agree that your use of the Service is at
          your sole risk. The Service (including the Service and the Content)
          are provided on an "AS IS" and "as available" basis, without
          warranties of any kind, either express or implied, including, without
          limitation, implied warranties of merchantability, fitness for a
          particular purpose or non-infringement. You acknowledge that
          <ProjectName /> has no control over, and no duty to take any action
          regarding: which users gain access to or use the Service; what effects
          the Content may have on you; how you may interpret or use the Content;
          or what actions you may take as a result of having been exposed to the
          Content. You release <ProjectName /> from all liability for you having
          acquired or not acquired Content through the Service. The Service may
          contain, or direct you to other websites containing information that
          some people may find offensive or inappropriate. <ProjectName /> makes
          no representations concerning any Content contained in or accessed
          through the Service, and <ProjectName /> will not be responsible or
          liable for the accuracy, copyright compliance, legality or decency of
          material contained in or accessed through the Service.
        </p>
      </Group>
      <Group id={getId(RC.LIMITATION_ON_LIABILITY)}>
        <h2>9. {getTitle(RC.LIMITATION_ON_LIABILITY)}</h2>
        <p>
          YOU ACKNOWLEDGE AND AGREE THAT YOU ASSUME FULL RESPONSIBILITY FOR YOUR
          USE OF THE SITE AND SERVICE. YOU ACKNOWLEDGE AND AGREE THAT ANY
          INFORMATION YOU SEND OR RECEIVE DURING YOUR USE OF THE SITE AND
          SERVICE MAY NOT BE SECURE AND MAY BE INTERCEPTED OR LATER ACQUIRED BY
          UNAUTHORIZED PARTIES. YOU ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE
          SITE AND SERVICE IS AT YOUR OWN RISK. RECOGNIZING SUCH, YOU UNDERSTAND
          AND AGREE THAT, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW,
          NEITHER <ProjectName /> NOR ITS SUPPLIERS OR LICENSORS WILL BE LIABLE
          TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
          PUNITIVE, EXEMPLARY OR OTHER DAMAGES OF ANY KIND, INCLUDING WITHOUT
          LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER
          TANGIBLE OR INTANGIBLE LOSSES OR ANY OTHER DAMAGES BASED ON CONTRACT,
          TORT, STRICT LIABILITY OR ANY OTHER THEORY (EVEN IF <ProjectName />{" "}
          HAD BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM
          THE SITE OR SERVICE; THE USE OR THE INABILITY TO USE THE SITE OR
          SERVICE; UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR
          DATA; STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SITE OR SERVICE;
          ANY ACTIONS WE TAKE OR FAIL TO TAKE AS A RESULT OF COMMUNICATIONS YOU
          SEND TO US; ANY INCORRECT, ILLEGIBLE, MISDIRECTED, STOLEN, INVALID OR
          INACCURATE ENTRY INFORMATION; HUMAN ERRORS; TECHNICAL MALFUNCTIONS;
          FAILURES, INCLUDING PUBLIC UTILITY OR TELEPHONE OUTAGES; OMISSIONS,
          INTERRUPTIONS, LATENCY, DELETIONS OR DEFECTS OF ANY DEVICE OR NETWORK,
          PROVIDERS, OR SOFTWARE (INCLUDING, BUT NOT LIMITED TO, THOSE THAT DO
          NOT PERMIT AN ENTRANT TO PARTICIPATE IN THE SERVICE); ANY INJURY OR
          DAMAGE TO COMPUTER EQUIPMENT; INABILITY TO FULLY ACCESS THE SITE OR
          SERVICE OR ANY OTHER WEBSITE; THEFT, TAMPERING, DESTRUCTION, OR
          UNAUTHORIZED ACCESS TO, OR ALTERATION OF, ENTRIES, IMAGES OR OTHER
          CONTENT OF ANY KIND; DATA THAT IS PROCESSED LATE OR INCORRECTLY OR IS
          INCOMPLETE OR LOST; TYPOGRAPHICAL, PRINTING OR OTHER ERRORS, OR ANY
          COMBINATION THEREOF; OR ANY OTHER MATTER RELATING TO THE SITE OR
          SERVICE. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,
          <ProjectName />
          'S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE
          FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID,
          IF ANY, BY YOU TO <ProjectName /> FOR GENERAL USE OF THE SITE OR
          SERVICE DURING THE TERM OF YOUR REGISTRATION FOR THE SITE, NOT
          INCLUDING ANY ENTRY FEES FOR CONTESTS COMPLETED PRIOR TO THE
          CIRCUMSTANCES GIVING RISE TO THE CLAIM.
        </p>
        <p>
          Some jurisdictions do not allow the exclusion of certain warranties or
          the limitation or exclusion of liability for incidental or
          consequential damages. Accordingly, some of the above limitations may
          not apply to you.
        </p>
      </Group>
      <Group id={getId(RC.OUR_PROPRIETARY_RIGHTS)}>
        <h2>10. {getTitle(RC.OUR_PROPRIETARY_RIGHTS)}</h2>
        <p>
          All title, ownership and intellectual property rights in and to the
          Service are owned by <ProjectName /> or its licensors. You acknowledge
          and agree that the Service contains proprietary and confidential
          information that is protected by applicable intellectual property and
          other laws. Except as expressly authorized by <ProjectName />, you
          agree not to copy, modify, rent, lease, loan, sell, distribute,
          perform, display or create derivative works based on the Service, in
          whole or in part.
        </p>
      </Group>
      <Group id={getId(RC.LINKS)}>
        <h2>11. {getTitle(RC.LINKS)}</h2>
        <p>
          The Service provides, or third parties may provide, links to other
          websites, applications or resources. Because <ProjectName /> has no
          control over such sites, applications and resources, you acknowledge
          and agree that <ProjectName /> is not responsible for the availability
          of such external sites, applications or resources, and does not
          endorse and is not responsible or liable for any content, advertising,
          products or other materials on or available from such sites or
          resources. You further acknowledge and agree that <ProjectName />{" "}
          shall not be responsible or liable, directly or indirectly, for any
          damage or loss caused or alleged to be caused by or in connection with
          use of or reliance on any such content, goods or services available on
          or through any such site or resource.
        </p>
      </Group>
      <Group id={getId(RC.TERMINATION_AND_SUSPENSION)}>
        <h2>12. {getTitle(RC.TERMINATION_AND_SUSPENSION)}</h2>
        <p>
          <ProjectName /> may terminate or suspend all or part of the Service
          and your <ProjectName /> account immediately, without prior notice or
          liability, if you breach any of the terms or conditions of the Terms.
          Upon termination of your account, your right to use the Service will
          immediately cease. If you wish to terminate your <ProjectName />{" "}
          account, you may contact us via the contact form with a note to say
          you wish to terminate your account.
        </p>
        <p>
          The following provisions of the Terms survive any termination of these
          Terms: CONDITIONS OF PARTICIPATION (except for Registration and
          Account Password and Security); GAME RULES (only Bonuses and
          Promotions); CONDUCT (only User Content); INDEMNITY; WARRANTY
          DISCLAIMERS; LIMITATION ON LIABILITY; OUR PROPRIETARY RIGHTS; LINKS;
          TERMINATION; NO THIRD-PARTY BENEFICIARIES; BINDING ARBITRATION AND
          CLASS ACTION WAIVER; GENERAL INFORMATION.
        </p>
        <p>
          If your account is subject to a suspension, you must respect the
          restrictions and limitations imposed on your account as part of the
          suspension, and you should communicate with <ProjectName /> regarding
          restoration of your account only via 
          <ProjectName support /> .
        </p>
      </Group>
      <Group id={getId(RC.NO_THIRD_PARTY_BENEFICIARIES)}>
        <h2>13. {getTitle(RC.NO_THIRD_PARTY_BENEFICIARIES)}</h2>
        <p>
          You agree that, except as otherwise expressly provided in these Terms,
          there shall be no third-party beneficiaries to the Terms.
        </p>
      </Group>
      <Group
        id={getId(
          RC.NOTICE_AND_PROCEDURE_FOR_MAKING_CLAIMS_OF_COPYRIGHT_INFRINGEMENT
        )}
      >
        <h2>
          14.{" "}
          {getTitle(
            RC.NOTICE_AND_PROCEDURE_FOR_MAKING_CLAIMS_OF_COPYRIGHT_INFRINGEMENT
          )}
        </h2>
        <p>
          <ProjectName /> may, in appropriate circumstances and at its
          discretion, disable and/or terminate the accounts of users who
          infringe the intellectual property of others. If you believe that your
          copyright or the copyright of a person on whose behalf you are
          authorized to act has been infringed, please provide <ProjectName /> a
          written Notice containing the following information:
        </p>
        <ul>
          <li>
            an electronic or physical signature of the person authorized to act
            on behalf of the owner of the copyright or other intellectual
            property interest;
          </li>
          <li>
            a description of the copyrighted work or other intellectual property
            that you claim has been infringed;
          </li>
          <li>
            a description of where the material that you claim is infringing is
            located on the Service;
          </li>
          <li>your address, telephone number, and email address;</li>
          <li>
            a statement by you that you have a good faith belief that the
            disputed use is not authorized by the copyright owner, its agent, or
            the law;
          </li>
          <li>
            a statement by you, made under penalty of perjury, that the above
            information in your Notice is accurate and that you are the
            copyright or intellectual property owner or authorized to act on the
            copyright or intellectual property owner's behalf.
          </li>
        </ul>
      </Group>
      <Group id={getId(RC.BINDING_ARBITRATION_AND_CLASS_ACTION_WAIVER)}>
        <h2>15. {getTitle(RC.BINDING_ARBITRATION_AND_CLASS_ACTION_WAIVER)}</h2>
        <QAItem>
          <p>15.1 Initial Dispute Resolution</p>
          <p>
            Our Customer Service Department is able to resolve most concerns
            quickly to our players’ satisfaction. The parties shall use their
            best efforts through this Customer Service process to settle any
            dispute, claim, question, or disagreement and engage in good faith
            negotiations which shall be a condition to either party initiating a
            lawsuit or arbitration.
          </p>
        </QAItem>
        <QAItem>
          <p>15.2 Binding Arbitration</p>
          <p>
            If the parties do not reach an agreed upon solution within a period
            of 30 days from the time informal dispute resolution under the
            Initial Dispute Resolution provision, then either party may initiate
            binding arbitration as the sole means to resolve claims, subject to
            the terms set forth below. Specifically, all claims arising out of
            or relating to these Terms (including their formation, performance
            and breach), the parties’ relationship with each other and/or your
            use of the Service shall be finally settled by binding arbitration
            administered by the American Arbitration Association in accordance
            with the provisions of its Commercial Arbitration Rules and the
            supplementary procedures for consumer related disputes of the
            American Arbitration Association (the “AAA”), excluding any rules or
            procedures governing or permitting class actions.
          </p>
          <p>
            The arbitrator, and not any federal, state or local court or agency,
            shall have exclusive authority to resolve all disputes arising out
            of or relating to the interpretation, applicability, enforceability
            or formation of these Terms, including, but not limited to any claim
            that all or any part of these Terms are void or voidable, or whether
            a claim is subject to arbitration. The arbitrator shall be empowered
            to grant whatever relief would be available in a court under law or
            in equity. The arbitrator’s award shall be written, and binding on
            the parties and may be entered as a judgment in any court of
            competent jurisdiction.
          </p>
          <p>
            The Commercial Arbitration Rules governing the arbitration may be
            accessed at www.adr.org or by calling the AAA. To the extent the
            filing fee for the arbitration exceeds the cost of filing a lawsuit,
            <ProjectName /> will pay the additional cost. The arbitration rules
            also permit you to recover attorney’s fees in certain cases. The
            parties understand that, absent this mandatory provision, they would
            have the right to sue in court and have a jury trial. They further
            understand that, in some instances, the costs of arbitration could
            exceed the costs of litigation and the right to discovery may be
            more limited in arbitration than in court.
          </p>
        </QAItem>
        <QAItem>
          <p>15.3 Location</p>
          <p>
            If you are a resident of the United States, arbitration will take
            place at any reasonable location within the United States convenient
            for you. For residents in Canada, arbitration shall be initiated in
            the County of New York, State of New York, United States of America,
            and you and <ProjectName /> agree to submit to the personal
            jurisdiction of any federal or state court in New York County, New
            York, in order to compel arbitration, to stay proceedings pending
            arbitration, or to confirm, modify, vacate or enter judgment on the
            award entered by the arbitrator.
          </p>
        </QAItem>
        <QAItem>
          <p>15.4 Class Action Waiver</p>
          <p>
            The parties further agree that any arbitration shall be conducted in
            their individual capacities only and not as a class action or other
            representative action, and the parties expressly waive their right
            to file a class action or seek relief on a class basis. YOU AND
            <ProjectName /> AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER
            ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR
            CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. If
            any court or arbitrator determines that the class action waiver set
            forth in this paragraph is void or unenforceable for any reason or
            that an arbitration can proceed on a class basis, then the
            arbitration provision set forth above shall be deemed null and void
            in its entirety and the parties shall be deemed to have not agreed
            to arbitrate disputes.
          </p>
        </QAItem>
        <QAItem>
          <p>
            15.5 Exception - Litigation of Intellectual Property and Small
            Claims Court Claims
          </p>
          <p>
            Notwithstanding the parties' decision to resolve all disputes
            through arbitration, either party may bring an action in state or
            federal court to protect its intellectual property rights
            (“intellectual property rights” means patents, copyrights, moral
            rights, trademarks, and trade secrets, but not privacy or publicity
            rights). Either party may also seek relief in a small claims court
            for disputes or claims within the scope of that court’s
            jurisdiction.
          </p>
        </QAItem>
        <QAItem>
          <p>15.6 30-Day Right to Opt Out</p>
          <p>
            You have the right to opt-out and not be bound by the arbitration
            and class action waiver provisions set forth above by sending
            written notice of your decision to opt-out to the following address:
            <ProjectName company />, <ProjectName address /> . The notice must
            be sent within 30 days of your first use of the Service, whichever
            is later, otherwise you shall be bound to arbitrate disputes in
            accordance with the terms of those paragraphs. If you opt-out of
            these arbitration provisions, <ProjectName /> also will not be bound
            by them.
          </p>
        </QAItem>
        <QAItem>
          <p>15.7 Changes to this Section</p>
          <p>
            <ProjectName /> will provide 60-days’ notice of any changes to this
            section. Changes will become effective on the 60th day, and will
            apply prospectively only to any claims arising after the 60th day.
          </p>
          <p>
            For any dispute not subject to arbitration you and <ProjectName />{" "}
            agree to submit to the personal and exclusive jurisdiction of and
            venue in the federal and state courts located in New York, NY. You
            further agree to accept service of process by mail, and hereby waive
            any and all jurisdictional and venue defenses otherwise available.
          </p>
          <p>
            The Terms and the relationship between you and <ProjectName /> shall
            be governed by the laws of the State of New York without regard to
            conflict of law provisions.
          </p>
        </QAItem>
      </Group>
      <Group id={getId(RC.APPLICATION_LICENSE)}>
        <h2>16. {getTitle(RC.APPLICATION_LICENSE)}</h2>
        <p>
          Subject to your compliance with these Terms, <ProjectName /> grants
          you a limited non-exclusive, non-transferable license to download and
          install a copy of the app on a device that you exclusively control and
          to run such copy of the app solely for your own personal use.
          <ProjectName /> reserves all rights in and to the app not expressly
          granted to you under these Terms. You will not run any version of the
          app on a jailbroken device.
        </p>
        <p>
          If you have downloaded our app, you agree to promptly download and
          install any new version that we make available through the iTunes App
          Store or Google Play store, as applicable. Some new versions may
          contain updated Terms. Some new versions may contain security fixes
          and service improvements, whether or not we disclose that they do;
          accordingly, failure to promptly update your version of the App may in
          some cases expose you to increased security risks or Service
          malfunctions.
        </p>
      </Group>
      <Group id={getId(RC.INTENTIONALLY_LEFT_BLANK)}>
        <h2>17. {getTitle(RC.INTENTIONALLY_LEFT_BLANK)}</h2>
      </Group>
      <Group id={getId(RC.GENERAL_INFORMATION)}>
        <h2>18. {getTitle(RC.GENERAL_INFORMATION)}</h2>
        <QAItem>
          <p>18.1 Entire Agreement</p>
          <p>
            These Terms and Conditions (and any additional terms, rules and
            conditions of participation in particular contests that{" "}
            <ProjectName />
            may post on the Service) constitute the entire agreement between you
            and <ProjectName /> with respect to the Service and supersedes any
            prior agreements, oral or written, between you and <ProjectName />.
            In the event of a conflict between these Terms and the additional
            terms, rules and conditions of participation in particular contests,
            the latter will prevail over the Terms to the extent of the
            conflict.
          </p>
        </QAItem>
        <QAItem>
          <p>18.2 Waiver and Severability of Terms</p>
          <p>
            The failure of <ProjectName /> to exercise or enforce any right or
            provision of the Terms shall not constitute a waiver of such right
            or provision. If any provision of the Terms is found by an
            arbitrator or court of competent jurisdiction to be invalid, the
            parties nevertheless agree that the arbitrator or court should
            endeavor to give effect to the parties' intentions as reflected in
            the provision, and the other provisions of the Terms remain in full
            force and effect.
          </p>
        </QAItem>
        <QAItem>
          <p>18.3 Statute of Limitations</p>
          <p>
            You agree that regardless of any statute or law to the contrary, any
            claim or cause of action arising out of or related to the use of the
            Service or the Terms must be filed within one (1) year after such
            claim or cause of action arose or be forever barred.
          </p>
        </QAItem>
        <QAItem>
          <p>18.4 Section Titles</p>
          <p>
            The section titles in the Terms are for convenience only and have no
            legal or contractual effect.
          </p>
        </QAItem>
        <QAItem>
          <p>18.5 Communications</p>
          <p>
            Users with questions, complaints or claims with respect to the
            Service may contact us using the relevant contact information set
            forth above.
          </p>
        </QAItem>
      </Group>
    </Layout>
  )
}
export default TermsOfUse
